import React from 'react'
import './ContractVehiclesContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'

export class ContractVehiclesContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  render() {
    return (
      <div className="contract-vehicles-content">
        <div className="split light subheader-block">
        <div className="row">
            <div className="col-sm-5">
              <img src="/gsalogo.png" />
            </div>
            <div className="col-sm-7">
              <div className="title">GSA MAS</div>
              <div className="description">
                Rackner is on the GSA Multiple Award Schedule for SIN 54151S
                <br></br>
                <div
                  style={{
                    fontSize: '17px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Contract #: 47QTCA23D006L
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <img src="/stars3logo.jpeg" />
            </div>
            <div className="col-sm-7">
              <div className="title">8(a) STARS III</div>
              <div className="description">
                Rackner was awarded the STARS III GWAC Contract using our JV,
                Rackner Vision, LLC
                <br></br>
                <div
                  style={{
                    fontSize: '17px',
                    marginTop: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Contract #: 47QTCB22D0510
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <img src="/aflogo.png" />
            </div>
            <div className="col-sm-7">
              <div className="title">ABMS IDIQ</div>
              <div className="description">
                Rackner was awarded the ABMS/JADC2 $950m IDIQ for our Edge,
                DevSecOps, and AI Capabilities across mission domains
              </div>
            </div>
          </div>
          <div
            style={{
              borderBottom: '1px solid #ddd',
              width: '800px',
              maxWidth: '100%',
              margin: '40px auto',
              marginTop: '0px',
            }}
          />
          <div className="row">
            <div className="col-sm-5">
              <img src="/HUBZoneCertified.png" />
            </div>
            <div className="col-sm-7">
              <div className="title">Hubzone Certified Business</div>
              <div className="description">
                Rackner is proud to be a HUBZone certified business located in
                Silver Spring, Maryland.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <img src="/kubernetes-kcsp-color.png" />
            </div>
            <div className="col-sm-7">
              <div className="title">Kubernetes Certified Service Provider</div>
              <div className="description">
                Rackner is highly experienced with Kubernetes with employees
                holding Kubernetes Certifications including the CKA, CKS, and
                CKAD.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <img src="/aws-partner-logo.png" />
            </div>
            <div className="col-sm-7">
              <div className="title">
                Amazon Web Services Consulting Partner
              </div>
              <div className="description">
                We use AWS heavily and are an official Consulting Partner with
                significant DevOps expertise.
              </div>
            </div>
          </div>
        </div>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default ContractVehiclesContent
