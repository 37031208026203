import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import ContractVehiclesHero from '../components/ContractVehicles/ContractVehiclesHero/ContractVehiclesHero'
import ContractVehiclesContent from '../components/ContractVehicles/ContractVehiclesContent/ContractVehiclesContent'

class Contact extends React.Component {
  render() {
    const postNode = {
      title: `Contact - ${config.siteTitle}`,
    }

    return (
      <Layout>
        <Helmet>
          <title>{`Contact - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postNode={postNode} pagePath="contact" customTitle />
        <ContractVehiclesHero />
        <ContractVehiclesContent />
      </Layout>
    )
  }
}

export default Contact
